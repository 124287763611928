<template>
  <div>
    <header>
      <div class="navbar navbar-dark bg-dark shadow-sm">
        <div class="container">
          <a href="/" class="navbar-brand d-flex align-items-center">
            <img
              src="@/assets/logo.png"
              alt="Logo XTC Indonesia"
              srcset=""
              width="52px"
            />
          </a>
          <div class="text-end" v-if="!currentUser">
            <router-link
              class="btn btn-light text-dark me-2"
              :to="{ name: 'login' }"
              >Masuk</router-link
            >
            <button
              type="button"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#registerModal"
            >
              Daftar
            </button>
            <!-- <router-link to="/register" class="btn btn-primary"
              >Daftar</router-link
            > -->
          </div>
          <div class="dropdown" v-else>
            <a
              class="nav-link dropdown-toggle white--text"
              id="userMenuDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="https://s3.eu-central-1.amazonaws.com/bootstrapbaymisc/blog/24_days_bootstrap/fox.jpg"
                width="40"
                height="40"
                class="rounded-circle"
              />
            </a>
            <ul class="dropdown-menu" aria-labelledby="userMenuDropdown">
              <li
                v-if="[role.SuperUser, role.Admin, role.PAC, role.DPC].includes(currentUser.role)"
              >
                <router-link class="dropdown-item" to="/admin"
                  >Admin panel</router-link
                >
              </li>
              <li>
                <router-link class="dropdown-item" to="/change_password"
                  >Ganti password</router-link
                >
              </li>
              <li>
                <button class="dropdown-item" @click="logout">Keluar</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>

    <!-- Modal Daftar -->
    <div
      class="modal fade"
      id="registerModal"
      tabindex="-1"
      aria-labelledby="registerModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Daftar menjadi Anggota
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="col-12 mx-auto">
              <p class="lead text-muted">
                Untuk mendaftar masukkan email kamu disini, kami akan
                mengirimkan link pendaftaran ke emailmu.
              </p>
              <div class="input-group mb-3">
                <input
                  type="email"
                  class="form-control"
                  placeholder="Email"
                  aria-label="Email"
                  aria-describedby="basic-addon2"
                  v-model="registerEmail"
                  :class="{ 'is-invalid': $v.registerEmail.$error }"
                />
                <div v-if="$v.registerEmail.$error" class="invalid-feedback">
                  <span v-if="!$v.registerEmail.required"
                    >Email harus di isi.</span
                  >
                  <span v-if="!$v.registerEmail.email"
                    >Format email harus benar.</span
                  >
                </div>
              </div>
              <div
                :class="isError ? 'alert alert-danger' : 'alert alert-success'"
                role="alert"
                v-if="sended"
              >
                {{ message }}
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Tutup
            </button>
            <button
              class="btn btn-primary"
              @click="daftar"
              :disabled="isLoading"
            >
              Daftar
            </button>
          </div>
        </div>
      </div>
    </div>

    <main>
      <section class="py-5 text-center container">
        <div class="row py-lg-5">
          <div class="col-lg-6 col-md-8 mx-auto">
            <h1 class="display-4 fw-bold">Daftar menjadi Anggota</h1>
            <p class="lead text-muted">
              Untuk mendaftar masukkan email kamu disini, kami akan mengirimkan
              link pendaftaran ke emailmu.
            </p>
            <div class="input-group mb-3">
              <input
                type="email"
                class="form-control"
                placeholder="Email"
                aria-label="Email"
                aria-describedby="basic-addon2"
                v-model="registerEmail"
                :class="{ 'is-invalid': $v.registerEmail.$error }"
              />
              <div v-if="$v.registerEmail.$error" class="invalid-feedback">
                <span v-if="!$v.registerEmail.required"
                  >Email harus di isi.</span
                >
                <span v-if="!$v.registerEmail.email"
                  >Format email harus benar.</span
                >
              </div>
            </div>

            <div class="d-grid gap-2">
              <button
                class="btn btn-primary"
                @click="daftar"
                :disabled="isLoading"
              >
                Daftar
              </button>
            </div>

            <div
              :class="isError ? 'alert alert-danger' : 'alert alert-success'"
              role="alert"
              v-if="sended"
            >
              {{ message }}
            </div>
          </div>
        </div>
      </section>
    </main>

    <footer class="text-muted py-5">
      <div class="container">
        <p class="float-end mb-1">
          <a href="#">Kembali ke atas</a>
        </p>
        <p class="mb-1">&copy; 2023 XTC Indonesia</p>
      </div>
    </footer>
  </div>
</template>

<script>
import { authenticationService } from "@/services";
import { required, email } from "vuelidate/lib/validators";
import { Role } from "@/helpers";
export default {
  name: "Landing",
  data() {
    return {
      registerEmail: "",
      sended: false,
      isLoading: false,
      isError: false,
      message: "",
      currentUser: authenticationService.currentUserValue,
      role: Role,
    };
  },
  methods: {
    async daftar() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isLoading = true;
        const baseURI = "/register/register_email";
        try {
          const result = await this.axios.post(baseURI, {
            email: this.registerEmail,
          });

          this.message = result.data.message;
          this.isError = false;
        } catch (error) {
          this.message = error.response.data.message;
          this.isError = true;
        }

        this.sended = true;
      }
    },
    logout() {
      authenticationService.logout();
      location.reload();
    },
  },
  validations: {
    registerEmail: {
      required,
      email,
    },
  },
};
</script>

<style scoped>
.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}
</style>
